/* src/styles/grid.css */
.grid-container {
  display: flex;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* Adjust number of columns as needed */
  gap: 20px;
  grid-template-rows: auto;
  padding: 20px;
  margin: 0 auto; /* Center the grid container */
  max-width: 1200px; /* Ensure the grid container does not exceed a maximum width */
  grid-template-areas:
    "item-3 item-3 item-3 item-3"
    "item-1 item-1 item-1 item-3"
    "item-4 item-5 item-6 item-7";
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: inset 0px 0px 15px 5px rgba(255, 234, 12, 0.72);
  transition: transform 0.3s;
  overflow: hidden;
}

.grid-item:hover {
  transform: translateY(-5px);
}

#item-3 {
  grid-area: item-3;
}

#item-2 {
  grid-area: item-2;
}

#item-3 {
  grid-area: item-3;
}

#item-4 {
  grid-area: item-4;
}

#item-5 {
  grid-area: item-5;
}

#item-6 {
  grid-area: item-6;
}

#item-7 {
  grid-area: item-7;
}

@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-template-areas:
      "item-3"
      "item-2"
      "item-1"
      "item-4"
      "item-5"
      "item-6"
      "item-7";
  }

  .grid-item.large,
  .grid-item.medium {
    grid-column: span 1;
    height: auto;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    margin-bottom: 20px; /* Add margin-bottom for spacing */
    grid-template-areas:
      "item-3"
      "item-2"
      "item-1"
      "item-4"
      "item-5"
      "item-6"
      "item-7";
  }

  .grid-item.large,
  .grid-item.medium {
    grid-column: span 1;
    height: auto;
  }
}
